import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('grid-layout',_vm._b({attrs:{"grid-props":{ 'selectable-key': 'id', 'show-select': _vm.canDelete },"selected-items":_vm.selected},on:{"update:selectedItems":function($event){_vm.selected=$event},"update:selected-items":function($event){_vm.selected=$event},"paramsChanged":_vm.reloadCollection},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(_vm.canCreate)?_c(VBtn,{attrs:{"depressed":"","color":"primary","to":{ name: 'product-create' }}},[_vm._v(" "+_vm._s(_vm.$t('Add new product'))+" ")]):_vm._e()]},proxy:true},{key:"scrollable.content.top",fn:function(){return [(_vm.errors)?_c('product-action-force-mass-delete-dialog',{attrs:{"items":_vm.selected},on:{"success":_vm.onMassDeleteSuccess},model:{value:(_vm.errors),callback:function ($$v) {_vm.errors=$$v},expression:"errors"}}):_vm._e(),_c('div',{staticClass:"my-4 d-flex flex-gap-24 justify-end"},[_c('product-import',{attrs:{"small":""},on:{"onSuccess":function($event){return _vm.reloadCollection(_vm.params)}}}),_c('product-export',{attrs:{"small":""}})],1),_c(VDivider)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'product-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.image_url",fn:function(ref){
var item = ref.item;
return [_c('image-zoom',{scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [_c('product-image-preview',{staticClass:"my-3 rounded-lg",attrs:{"value":item.image_url,"height":"40","width":"40"}})]},proxy:true},{key:"zoom",fn:function(){return [_c('product-image-preview',{attrs:{"value":item.image_url,"max-width":"300"}})]},proxy:true}],null,true)})]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'product-update', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.sku)+" ")])]}},{key:"item.price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.price}})]}},{key:"item.gross_price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.gross_price}})]}},{key:"item.sale_price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.sale_price}})]}},{key:"item.gross_sale_price.amount",fn:function(ref){
var item = ref.item;
return [_c('money-preview',{attrs:{"value":item.gross_sale_price}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('button-action',{attrs:{"icon-name":"edit","icon":"","label":_vm.$t('Edit'),"disabled":_vm.grid.loading,"to":{ name: 'product-update', params: { id: item.id } }}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('product-action-delete',{attrs:{"value":item,"icon":""},on:{"success":_vm.onDeleteSuccess}})]}},{key:"grid.footer.actions",fn:function(){return [_c('div',[(_vm.canDelete)?_c('product-action-mass-delete',{attrs:{"items":_vm.selected,"disabled":_vm.grid.loading || !_vm.selected.length},on:{"success":_vm.onMassDeleteSuccess,"error":function($event){_vm.errors = $event}}}):_vm._e(),_c('button-action',{attrs:{"label":_vm.$t('Force delete'),"items":_vm.selected,"text":"","disabled":_vm.grid.loading || !_vm.selected.length},on:{"click":function($event){_vm.errors = { message: _vm.$t('Confirm force delete') }}}})],1)]},proxy:true}])},'grid-layout',{ grid: _vm.grid, params: _vm.params, options: _vm.options, title: _vm.$t('Products') },false))}
var staticRenderFns = []

export { render, staticRenderFns }