import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form-validator-wrapper',{on:{"submit":_vm.createHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('scrollable-resource',{staticClass:"d-flex flex-column",attrs:{"items":_vm.sections,"eager":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('resource-header',{attrs:{"back-to":{ name: 'products' },"header":_vm.$t('Add new product')},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-end flex-gap-16"},[_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"primary","loading":_vm.loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1)]},proxy:true}],null,true)}),_c(VDivider)]},proxy:true},_vm._l((_vm.requiredSections),function(section){return {key:("tab-label-" + section),fn:function(ref){
var item = ref.item;
return [_c('span',{key:section,staticClass:"required"},[_c('label',[_vm._v(_vm._s(item.name))])])]}}}),{key:"content-top",fn:function(){return [(_vm.isBundlePart)?_c('div',{key:_vm.item.id,staticClass:"d-flex flex-column align-center"},[_c('product-bundle-warning',{staticClass:"mt-3 col-10",attrs:{"items":_vm.item.associated_with_bundles}})],1):_vm._e()]},proxy:true},_vm._l((_vm.sections),function(section){return {key:("content-" + (section.code)),fn:function(ref){
var content = ref.content;
return [(_vm.item)?_c('div',{key:("content-" + (section.code)),staticClass:"d-flex flex-column align-center"},[_c(content.component,{tag:"component",staticClass:"mt-3 col-10",attrs:{"validator":_vm.validator},on:{"onTranslationUpdate":_vm.onTranslationUpdate},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1):_vm._e()]}}})],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }