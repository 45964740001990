import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.translationSource || _vm.missingTranslations.length)?_c('subresource-expansion-panel',{attrs:{"can-create":true},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',[_vm._v(_vm._s(!_vm.translationSource ? _vm.$t('Add description') : _vm.$t('Add translation')))])]},proxy:true},{key:"content",fn:function(){return [_c('form-validator-wrapper',{key:_vm.descriptionTableSize,staticClass:"mt-4",on:{"submit":_vm.addItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(!!_vm.translationSource && _vm.missingTranslations.length)?_c(VSelect,{attrs:{"items":_vm.missingTranslations,"item-text":"title","item-value":"key","outlined":"","label":_vm.$t('Select description to translate'),"rules":_vm.rules},model:{value:(_vm.newDescriptionTab.key),callback:function ($$v) {_vm.$set(_vm.newDescriptionTab, "key", $$v)},expression:"newDescriptionTab.key"}}):_vm._e(),(_vm.newDescriptionTab.key)?_c('product-description-input',{model:{value:(_vm.newDescriptionTab),callback:function ($$v) {_vm.newDescriptionTab=$$v},expression:"newDescriptionTab"}}):_vm._e(),_c('div',{staticClass:"d-flex justify-end mt-4"},[_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Submit'))+" ")])],1)]}}],null,false,3021614682)})]},proxy:true}],null,false,599653713)}):_c(VAlert,{attrs:{"type":"info","dismissible":""}},[_c('span',[_vm._v(_vm._s(_vm.$t('Every description is translated :)')))])]),_c(VDataTable,{staticClass:"v-data-table-shadowless",attrs:{"show-expand":"","single-expand":"","items":_vm.translationSource ? _vm.translatedDescriptions : _vm.form,"headers":_vm.headers,"hide-default-footer":"","item-key":"key"},on:{"item-expanded":_vm.onExpand},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{key:item.key,staticClass:"py-6",attrs:{"colspan":headers.length}},[(_vm.expandedDescriptionTab)?_c('form-validator-wrapper',{on:{"submit":function($event){return _vm.updateItem(item.key, _vm.expandedDescriptionTab)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('product-description-input',{model:{value:(_vm.expandedDescriptionTab),callback:function ($$v) {_vm.expandedDescriptionTab=$$v},expression:"expandedDescriptionTab"}}),_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){return _vm.onDelete(item.key)}}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")]),_c(VBtn,{attrs:{"disabled":!valid,"depressed":"","color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)]}}],null,true)}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }