import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',[_vm._v(_vm._s(_vm.$t('Variants')))]),_c(VBtn,{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.addVariant}},[_vm._v(" "+_vm._s(_vm.$t('Add variant'))+" ")])],1),(_vm.hasProductVariants)?_c('translation-tabs',{staticClass:"px-0 mx-0 mt-4",attrs:{"translatable-values":['variants']},scopedSlots:_vm._u([{key:"default.variants",fn:function(ref){
var code = ref.code;
return _vm._l((_vm.variants),function(variant,index){return _c('div',{key:("default-" + (variant.key))},[_c('expandable-content',{attrs:{"eager":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-column grey--text text-caption"},[_c('span',[_vm._v(_vm._s('Name')+": "+_vm._s(variant.name || 'N/A'))]),_c('span',[_vm._v(_vm._s(_vm.$t('Options'))+": "+_vm._s(variant.options.join(', ') || 'N/A'))])])]},proxy:true},{key:"after-icon",fn:function(){return [_c('product-action-delete-variant',{key:variant.key,attrs:{"icon":"","variant":variant,"suppress-confirmation":!_vm.value.id},model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}})]},proxy:true},{key:"content",fn:function(){return [_c('product-variants-input',{key:("variants-" + index),staticClass:"mx-4 my-4",attrs:{"value":variant,"breadcrumb-error-code":("variants-" + code + "-" + index)},on:{"input":_vm.updateVariant}})]},proxy:true}],null,true)})],1)})}},{key:"variants",fn:function(ref){
var code = ref.code;
var currentTab = ref.currentTab;
return _vm._l((_vm.translations(code).get()),function(variant,index){return _c('div',{key:((variant.key) + "-" + code)},[_c('expandable-content',{attrs:{"eager":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex flex-column grey--text text-caption"},[_c('span',[_vm._v(_vm._s('Name')+": "+_vm._s(variant.name || 'N/A'))]),_c('span',[_vm._v(_vm._s(_vm.$t('Options'))+": "+_vm._s(variant.options.join(', ') || 'N/A'))])])]},proxy:true},{key:"content",fn:function(){return [(_vm.getVariantSource(variant).value)?_c('product-variants-input',{key:("variants-" + index + "-" + code + "-" + currentTab),staticClass:"mx-4 my-4",attrs:{"value":variant,"source":_vm.getVariantSource(variant).value,"breadcrumb-error-code":("variants-" + code + "-" + index)},on:{"input":function($event){return _vm.updateVariantTranslation($event, code)}}}):_vm._e()]},proxy:true}],null,true)})],1)})}}],null,false,3420159888)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }